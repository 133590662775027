<template>
    <main-container>
        <header-container>
            <ListOPDAppointmentHeader />
        </header-container>
        <div style="background-color: white; padding: 16px 20px 16px 20px;">
            <div class="appointmentTypeContainer">
                <div :class="calculateClassForAppointmentTypeTab('upcoming')" @click="toggleTab">{{$t("Customer.Appointments.upcoming")}}</div>
                <div :class="calculateClassForAppointmentTypeTab('completed')" @click="toggleTab">{{$t("Customer.Appointments.completed")}}</div>
            </div>
        </div>
        <div style="height: 85vh; max-height: 85vh; overflow-y: scroll;" id="scrollableDiv">
            <body-container>
                <div v-if="activeTab == 'upcoming'" style="padding-bottom: 30px;">
                    <div v-if="appointmentLoading" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 60vh; font-size: 30px;"><v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular></div>
                    <div v-else-if="activeAppointments.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey;  height: 60vh;">
                        <img src="https://s3iconimages.mymedicine.com.mm/noContentFound.svg" alt="">
                        {{$t("Customer.Appointments.No_appointment_found")}}
                    </div>
                    <single-appointment-component v-for="item in activeAppointments" :completedFlag="false"  :key="item.bookingID" :appointmentInfo="item"/>
                </div>
                 <div v-else style="padding-bottom: 30px;">
                    <div v-if="appointmentLoading" style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 60vh; font-size: 30px;"><v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular></div>
                    <div v-else-if="completedAppointments.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey;  height: 60vh;">
                        <img src="https://s3iconimages.mymedicine.com.mm/noContentFound.svg" alt="">
                        {{$t("Customer.Appointments.No_appointment_found")}}
                    </div>
                    <single-appointment-component v-for="item in completedAppointments" :completedFlag="true"  :key="item.bookingID" :appointmentInfo="item"/>
                </div>
            </body-container>
        </div>

    </main-container>
</template>
<script>
import axios from "axios";
import BodyContainer from '../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import mainContainer from '../../../templates/mainContainer.vue';
import HospitalSkeletonLoader from '../componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import HospitalHeaderComp from '../componentUsedInPage/opdHomePage/hospitalHeaderComp.vue';
import SelectRegionAndSeachComp from '../componentUsedInPage/opdHomePage/selectRegionAndSeachComp.vue';
import SingleHospitalComp from '../componentUsedInPage/opdHomePage/singleHospitalComp.vue';
import ListOPDAppointmentHeader from '../componentUsedInPage/listOPDAppointmentsPage/listOPDAppointmentHeader.vue';
import SingleAppointmentComponent from '../componentUsedInPage/listOPDAppointmentsPage/singleAppointmentComponent.vue';
import { axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
export default {
  components: { mainContainer, BodyContainer, HeaderContainer, HospitalHeaderComp, SelectRegionAndSeachComp, SingleHospitalComp, HospitalSkeletonLoader, ListOPDAppointmentHeader, SingleAppointmentComponent },
    name: 'ListOPDAppointmentsPage',
    data: function() {
        return {
            token: null,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            activeTab: "upcoming",
            completedAppointments: [],
            activeAppointments: [],
            appointmentLoading: false,
        };
    },
    computed: {
        completedFlag() {
            if(this.activeTab == 'completed') return true;
            return false;
        }
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        try {
            this.appointmentLoading = true;
            let apiResponse = await axios_auth_instance_hba_customer.get('/appointment');
            this.activeAppointments = apiResponse.data.appointmentList.activeAppointments;
            this.completedAppointments = apiResponse.data.appointmentList.completedAppointments;
            this.appointmentLoading = false;
        } catch (error) {
            console.log(error);
        }

        this.userPageViewTable();
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'HBAMyOPDBookings',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'HBAMyOPDBookings',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        calculateClassForAppointmentTypeTab(value) {
            if(this.activeTab == value) {
                return "activeButtonAppointmentTypeTab"
            } else {
                return "inActiveButtonAppointmentTypeTab"
            }
        },
        toggleTab() {
            if(this.activeTab === 'completed') {
                this.activeTab = 'upcoming';
            } else {
                this.activeTab = 'completed';
            }

        }
    },
};
</script>
<style  scoped>
.appointmentTypeContainer {
    border: 1px solid #e0e0e0;
    padding: 6px;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
}
.activeButtonAppointmentTypeTab {
    background: #48acef;
    padding: 4px 10px 4px 10px;
    color: white;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    height: 32px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;

}
.inActiveButtonAppointmentTypeTab {
    background: white;
    cursor: pointer;
    padding: 4px 10px 4px 10px;
    color: black;
    font-weight: 500;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    flex-grow: 1;
    justify-content: center;
}
.allDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background: white;
    border-bottom: 1px solid #ebebeb;
    position: sticky;
    max-width: 425px;
    top: 0;
}
</style>